.container {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  padding-top: 38px;
}

.userContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 20px 0 24px;
  padding-bottom: 4px;

  border-bottom: none;
}

.userInfoContainer {
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;

  transition: padding-left 200ms ease;
}

.avatarLinkClassName {
  position: absolute !important;
  top: auto;
  bottom: 0;
  left: 50%;

  flex-shrink: 0;

  width: fit-content;
  height: fit-content;
  margin-bottom: 8px !important;

  transform: translate(-50%, -50%) !important;
}

.userAvatar {
  width: 96px !important;
  height: 96px !important;
}

.userAvatar > * {
  font-size: 16px !important;
}

.userDetails {
  display: flex;
  flex-direction: column;

  gap: 6px;
}

.usernameSection {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  gap: 6px;

  margin-bottom: 4px;
}

.streamerIcon {
  width: 20px;
  height: 20px;
}

.userName {
  font-weight: 600 !important;

  overflow-x: hidden;
  overflow-y: hidden; /* windows bug */

  white-space: nowrap;
  text-overflow: ellipsis;
}

.userBadges {
  display: flex;
}

.userBadges > *:not(:last-child) {
  margin-right: 4px;
}

.controlsContainer {
  display: flex;
}

@media (max-width: 540px) {
  .karmaValueClassname {
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 20px !important;

    margin-left: 4px;
  }

  .onlineContainerClassname {
    width: 12px;
    height: 12px;
  }
}
