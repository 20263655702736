.container {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden; /* windows bug */
  align-items: center;

  width: fit-content;
  max-width: 100%;
  height: 20px;
  padding: 4px;

  background-color: var(--white-10);
}

.iconContainer {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden; /* windows bug */
  align-items: center;
  justify-content: center;

  width: 20px;
  height: 20px;

  border-radius: 4px;
  background-color: var(--white-10);
}

.linkContainter {
  border: none;
  border-radius: 4px;
}

:not(.linkContainter) > .label {
  color: var(--white);
}

:not(.linkContainter) > .icon {
  fill: var(--white);
}

.linkWrapper {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden; /* windows bug */
  align-items: center;

  padding-right: 8px;

  cursor: pointer;
}

.linkIconWrapper {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden; /* windows bug */
  align-items: center;

  width: 20px;
  height: 20px;

  cursor: pointer;

  border-radius: 4px;
  background-color: var(--white-10);
}

.icon {
  flex-shrink: 0;

  margin-right: 8px;

  transition: fill 150ms ease;
  fill: var(--white-48);
}

.linkIcon {
  width: 20px;
  height: 20px;

  transition: fill 150ms ease;
  fill: var(--white);
  stroke: var(--white);
}

.linkWrapper:hover > .icon {
  fill: var(--white);
}

.label {
  overflow-x: hidden;
  overflow-y: hidden; /* windows bug */

  white-space: nowrap;
  text-overflow: ellipsis;

  color: var(--white-48);

  transition: color 150ms ease;
}

.linkWrapper:hover > .label {
  color: var(--white);
}

.copyWrapper {
  display: flex;
  align-items: center;

  cursor: pointer;

  border-left: 1px solid var(--white-48);

  transition: border-color 150ms ease;
}

.copyWrapper > svg {
  width: 12px;
  height: 12px;
  margin-left: 8px;

  transition: fill 150ms ease;
  fill: var(--white-48);
}

.copyWrapper:hover {
  border-color: var(--white);
}

.copyWrapper:hover > svg {
  fill: var(--white);
}

/* @media (min-width: 1366px) and (max-width: 1920px) {
  .container {
    padding: 6px 4px;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 2px;
  }

  .copyWrapper > svg {
    margin-right: 4px;
  }
}

@media (max-width: 1366px) {
  .container {
    padding: 4px;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  .copyWrapper > svg {
    margin-right: 4px;
  }
} */

/* sdasd */
.socialIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  fill: var(--white-48);

  transition: fill 150ms ease;
}

.socialText {
  color: var(--white-40);

  transition: color 150ms ease;
}

.socialLink:hover > .socialIcon {
  fill: var(--white);
}

.socialLink:hover > .socialText {
  color: var(--black-10);
}

@media (max-width: 540px) {
  .iconContainer {
    width: 32px;
    height: 32px;
  }

  .linkIconWrapper {
    width: 32px;
    height: 32px;

    border-radius: 6px;
  }

  .linkIcon {
    width: 32px;
    height: 32px;
  }
}
