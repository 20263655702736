.container {
  display: flex;
  flex-direction: column;

  margin-top: 12px;
}

.titleText {
  margin-top: 12px;
  margin-bottom: -8px; /* To prevent gap effect */
}

.descriptionText {
  font-size: 15px !important;

  margin-bottom: 12px;

  text-align: center;
  white-space: pre-line;

  color: var(--dark-48);
}

.aboutContainer {
  display: flex;
  flex-direction: column;
}

.aboutTitle {
  margin-bottom: 8px;

  user-select: none;
}

.aboutWrapper {
  display: flex;

  gap: 32px;
}

.organizationLinks {
  display: flex;
  gap: 12px;
}

.userSocial {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  width: 100%;
  gap: 13px;

  margin-bottom: 16px;
}

.socialIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border-radius: 6px;
  background: var(--dark-12);
}

.socialIcon {
  width: 32px;
  height: 32px;
}

.socialValue {
  align-self: center;

  padding-left: 6px;

  color: var(--white);
}

.calendar {
  margin-top: 8px;
}

.followingInfo {
  display: flex;
  gap: 6px;

  justify-content: center;

  margin-bottom: 16px;
}

.followingInfoText {
  display: flex;
  align-items: center;

  color: var(--dark-64);
}

.followersLink {
  display: flex;
  align-items: center;
}

.followersLink:first-child:after {
  width: 3px;
  height: 3px;
  margin-left: 6px;

  content: '';

  border-radius: 50%;
  background-color: var(--dark-48);
}

.profileButtonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 64px;
  padding: 0 20px;
  gap: 4px;
}

.profileButtonsWrapperWithShop {
  margin-bottom: 16px !important;
}

.profileButtonsWrapper > * {
  width: 100%;
}
