.container {
  --gradient-radial: radial-gradient(50% 100% at center 28px, transparent, #050505 100%);
  --feed-wrapper-scroll-height-until-stick: 0px;
  --feed-wrapper-border-radius: 20px;
  --wrapper-padding: 24px;
  --wrapper-offset-top: calc(var(--app-header-height) + var(--feed-tabs-height) - 4px);
  /* Add border radius so content won't soak at borders */
  --feed-background-offset-top: -28px;
  --feed-background-height: calc(var(--wrapper-offset-top) + var(--feed-wrapper-border-radius) - var(--feed-background-offset-top));

  --feed-footer-height: 64px;

  position: relative;

  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.columnCenterContainer {
  position: relative;

  display: flex;
  flex-direction: column;

  width: var(--app-center-content-width);
  /* margin-top: var(--app-header-height); */
}

.columnCenterWrapper {
  position: relative;

  display: flex;
  flex-direction: column;

  width: var(--app-center-content-width);
  min-height: calc(100vh - var(--wrapper-offset-top) - 24px);
  /* min-height: 200vh; */

  margin-bottom: 24px;

  border-radius: var(--feed-wrapper-border-radius);
  background-color: var(--color-wrapper-bg);
  /* Ignore box-shadow — it is only upward */
  /* box-shadow: var(--inset-shadow); */
}

.wrapperWithFooter {
  min-height: calc(100vh - var(--wrapper-offset-top) - (24px + var(--feed-footer-height) / 2));
  margin-bottom: calc(24px + var(--feed-footer-height) / 2);
}

.columnRightContainer {
  z-index: 21;

  width: var(--app-right-section-width);
  margin-left: var(--app-right-section-gap);
}

.columnRightWrapper {
  position: sticky;
  top: var(--app-header-height);

  height: calc(100vh - var(--app-header-height) - 24px);
}
