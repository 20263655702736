.container {
  z-index: inherit;

  display: flex;

  width: 100%;
  margin-bottom: 16px;
  /* margin-bottom: 20px; */

  list-style-type: none;

  border-bottom: 1px solid var(--white-8);
}

.tab {
  font-size: 14px;

  padding: 0 10px;

  cursor: pointer;

  color: var(--white-48);
}

.tab:hover {
  color: var(--white);
}

.tab:first-of-type {
  padding-left: 0;
}

.tabActive,
.tabActive:hover {
  color: #fff;
}

.tabDisabled,
.tabDisabled:hover {
  pointer-events: none;

  color: var(--dark-32);
}

.tabWrapper {
  margin-bottom: 1px;
  padding-bottom: 10px;

  color: var(--white-48);
}

.tabWrapper:hover {
  color: var(--white-80);
  /* TODO: Design-system */
  /* Prevents stacking borders */
  box-shadow: 0 1px 0 0 var(--black-8);
}

.tabWrapperActive,
.tabWrapperActive:hover {
  /* Prevents stacking borders */
  color: var(--white);
  box-shadow: 0 2px 0 0 var(--white);
}

.gap {
  flex-grow: 1;
}

.rightSection {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
