.backgroundContainer {
  height: 200px;
  margin: 10px;
  margin-bottom: 16px;

  border-radius: 12px;
}

@media (min-width: 1900px) {
  .backgroundContainer {
    height: 235px;
  }
}

.backgroundImage {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  border-radius: inherit;
  background-color: var(--dark-12);
  background-size: cover;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  filter: drop-shadow(0 8px 24px rgba(0, 0, 0, 0.32));
}

.contentContainer {
  --dark-next: var(--dark-12);
  --dark-next-2: var(--dark-20);
  padding: 0 var(--wrapper-padding);
  padding-bottom: 20px;
}

.backgroundImageUnset {
  background-color: var(--dark-20);
  background-image: url('https://storage.googleapis.com/lfgcdn/assets/logo-2@2x.png');
  background-size: 12%;
  background-blend-mode: color;
}

.contentTabsContainer {
  padding: 0 var(--wrapper-padding);
}

.userShopBannerContainer {
  position: relative;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 2px 0 24px;
  padding: 24px;

  background-color: var(--green);
}

.userShopBanner {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;

  text-align: center;
  gap: 4px;
}

.userShopBannerLabel {
  margin-bottom: 4px;

  color: var(--dark);
}
