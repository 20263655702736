
.userShopBannerContainer {
  position: relative;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 8px 0;
  padding: 24px;

  background-color: var(--green);
}

.userShopBanner {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;

  text-align: center;
  gap: 4px;
}

.userShopBannerLabel {
  margin-bottom: 4px;

  color: var(--dark);
}
