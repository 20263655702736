.container {
  display: flex;
  align-items: cener;


  margin: 0 var(--wrapper-padding);
  margin-bottom: var(--wrapper-padding);
  height: 48px;
}

.avatarWrapper {
  flex-shrink: 0;
  width: 96px;
  height: 96px;
  margin-right: 16px;
  margin-top: -48px;
}

.userContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 20px 0 24px;
  padding-bottom: 4px;

  border-bottom: none;
}

.userAvatar {
  width: 96px;
  height: 96px;
}

.infoContainer {
  position: relative;

  display: flex;
  align-items: flex-end;
  align-self: flex-end;
  flex-grow: 1;

  width: 100%;

  transition: padding-left 200ms ease;
}


.userDetails {
  display: flex;
  flex-direction: column;
}

.usernameSection {
  display: flex;
  align-items: center;
  gap: 6px;

  width: 100%;
  margin-bottom: 4px;
}

.streamerIcon {
  width: 20px;
  height: 20px;
}

.userName {
  overflow-x: hidden;
  overflow-y: hidden;
  /* windows bug */

  /* margin-bottom: 4px; */

  white-space: nowrap;
  text-overflow: ellipsis;
}

.controlsContainer {
  flex-shrink: 0;
  display: flex;
}