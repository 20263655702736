.container {
  display: flex;
  flex-direction: column;

  margin-bottom: 24px;
  padding: 0 var(--wrapper-padding);

  gap: 16px;
}

.titleText {
  margin-top: 12px;
  margin-bottom: -8px;
  /* To prevent gap effect */
}

.descriptionText {
  white-space: pre-line;

  color: var(--dark-64);
}

.aboutContainer {
  display: flex;
  flex-direction: column;
}

.aboutTitle {
  margin-bottom: 8px;

  user-select: none;
}

.aboutWrapper {
  display: flex;

  gap: 32px;
}

.userSocial {
  display: grid;

  width: fit-content;
  min-width: 460px;

  grid-row-gap: 8px;
  row-gap: 8px;
  grid-template-columns: auto 1fr auto 1fr;
}

.socialIcon {
  width: 24px;
  height: 24px;
}

.socialValue {
  align-self: center;

  padding-left: 6px;

  cursor: pointer;

  color: var(--white);
}

.calendar {
  margin-top: 8px;
}
