.layoutMobileUserProfile {
  position: relative;

  display: flex;
  flex-direction: column;

  height: 100%;
  padding-bottom: 65px;

  background-color: var(--dark);
}

.settingsButton {
  position: absolute;
  top: 16px;
  right: 16px;
}

.mobilePage {
  display: flex;
  flex-direction: column;

  width: 100%;
  min-width: 375px;
  max-width: 100vw;
  min-height: 100vh;

  background-color: var(--dark);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
}

.error {
  margin: 0;
  padding-top: 12px;
  padding-left: 40px;
}

.headerBackground {
  right: 0;

  width: 100%;
  height: 190px;

  border-radius: 12px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background: linear-gradient(0.25turn, #3d81a6, #3b2c3a);
}

.headerThemeImageWrapper {
  width: 100%;
  height: 100%;

  border-radius: 12px;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background: linear-gradient(0.25turn, #3d81a6, #3b2c3a);
  background-color: var(--dark-20);
  background-position: center;
  background-size: cover;
}

.headerThemeDefaultImageWrapper {
  background-position: 0% 60% !important;
  background-size: 12% !important;
  /* background-size: cover; */
  background-blend-mode: color !important;
}

.profileInfo {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 0 20px;
}

.logoContainer {
  z-index: 10;

  align-self: flex-start;

  width: 39px;
  height: 24px;
  margin-top: 16px;
  margin-bottom: 22px;
}

.logoContainer > * {
  fill: var(--white) !important;
}

.userAvatar {
  width: 96px;
  height: 96px;
}

.userName {
  align-self: center;

  margin-top: 10px;
}

.userDescription {
  margin-top: 20px;

  text-align: center;

  color: var(--dark-64);
}

.socialLinks {
  margin-top: 17px;
}

.socialIcon {
  width: 43px;
  height: 43px;

  background-color: transparent !important;
}

.linksContent {
  display: flex;
  gap: 26px;
}

.social {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin-bottom: 24px;
}

.gamesInfo {
  padding: 20px 20px 24px;

  border-top: 1px solid var(--dark-12);
}

.gameIcons {
  gap: 16px;
}

.gameIconsLabel {
  font-size: 14px !important;
  font-weight: 600 !important;
  line-height: 18px !important;

  text-transform: none !important;

  color: var(--white);
}

.linksLabel {
  font-weight: 600 !important;

  margin-bottom: 2px;
}

.userProfileButtonsWrapper {
  padding: 0 16px;
}

.userProfileButtons {
  display: flex;
  justify-content: space-between;
  gap: 8px;

  margin-top: 24px;
}

.userProfileButtons > * {
  width: 100% !important;
}
