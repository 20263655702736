.followButton {
  max-width: 160px;
}

.userActions {
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  gap: 8px;
}
